import React, { ReactElement } from "react";
import { useToggle } from "react-use";
import { Icon, IconProps, List, Button, Collapsible, Tooltip } from "@shopify/polaris";

// == Types ================================================================

interface IProps {
  message: string;
  details: (string | undefined | ReactElement)[];
  displayDetails: boolean;
  iconSource: IconProps["source"];
  iconColor: IconProps["color"];
  disableAction: boolean;
  actionText?: string;
  actionTooltip?: string;
  onClickAction?: () => void;
}

// == Constants ============================================================

IconMessage.defaultProps = {
  details: [],
  displayDetails: false,
  iconColor: "black",
  disableAction: false,
};

// == Component ============================================================

export function IconMessage({
  message,
  details,
  displayDetails,
  iconSource,
  iconColor,
  actionText,
  actionTooltip,
  onClickAction,
  disableAction,
}: IProps) {
  const [showDetails, toggleShowDetails] = useToggle(displayDetails);

  const renderActionButton = () => {
    if (!actionText) return null;

    const button = (
      <Button
        disabled={disableAction}
        onClick={() => {
          if (typeof onClickAction === "function") onClickAction();
        }}
      >
        {actionText}
      </Button>
    );
    if (!actionTooltip) return button;
    return (
      <Tooltip activatorWrapper="p" content={actionTooltip} preferredPosition="mostSpace">
        {button}
      </Tooltip>
    );
  };

  return (
    <div className="icon-message">
      <div>
        <Icon color={iconColor} source={iconSource} />
        <span>
          {details.length > 0 ? (
            <Button disclosure plain size="slim" onClick={toggleShowDetails}>
              {message}
            </Button>
          ) : (
            message
          )}
        </span>
        {renderActionButton()}
      </div>
      {details.length > 0 && (
        <Collapsible id="basic-collapsible" open={showDetails}>
          <List type="bullet">
            {details.map((msg, index) => {
              if (!msg) return null;
              // eslint-disable-next-line react/no-array-index-key
              return <List.Item key={index}>{msg}</List.Item>;
            })}
          </List>
        </Collapsible>
      )}
    </div>
  );
}

// == Styles ===============================================================
