import React from "react";
import { FooterHelp, Link } from "@shopify/polaris";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export function DashboardFooter() {
  return (
    <FooterHelp>
      <Link external url="https://www.flipgive.com/pages/contact">
        Contact FlipGive for support
      </Link>
    </FooterHelp>
  );
}

// == Styles ===============================================================
