import React from "react";
import { AppProps } from "next/app";
import { AppProvider, EmptyErrorState } from "@shared/components";
import { BugsnagErrorBoundary } from "@utils";

import "@shopify/polaris/dist/styles.css";
import "@shared/styles/index.css";

// == Types ================================================================

// == Constants ============================================================

// == Functions ============================================================

// == Component ============================================================

export default function App({ Component, pageProps }: AppProps) {
  return (
    <BugsnagErrorBoundary FallbackComponent={() => <EmptyErrorState />}>
      <AppProvider>
        <Component {...pageProps} />
      </AppProvider>
    </BugsnagErrorBoundary>
  );
}

// == Styles ===============================================================
