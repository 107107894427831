import React, { ReactNode } from "react";
import { AppProvider as ShopifyAppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";

// == Types ================================================================

interface IProps {
  children: ReactNode;
}

// == Constants ============================================================

// == Component ============================================================

export function AppProvider({ children }: IProps) {
  return <ShopifyAppProvider i18n={translations}>{children}</ShopifyAppProvider>;
}

// == Styles ===============================================================
