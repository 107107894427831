import React from "react";
import { Card, DescriptionList, DescriptionListProps } from "@shopify/polaris";

// == Types ================================================================

interface IProps {
  statuses: DescriptionListProps["items"];
}

// == Constants ============================================================

// == Component ============================================================

export function DashboardStatusList({ statuses }: IProps) {
  return (
    <Card sectioned>
      <DescriptionList items={statuses} />
    </Card>
  );
}

// == Styles ===============================================================
