import React, { ReactNode } from "react";
import { Page, Layout } from "@shopify/polaris";
import { DashboardFooter } from "./DashboardFooter";

// == Types ================================================================

interface IProps {
  children: ReactNode;
}

// == Constants ============================================================

// == Component ============================================================

export function DashboardLayout({ children }: IProps) {
  return (
    <Page
      thumbnail={<img alt="flipgive logo" height="50" src="/flipgive_logo_black.png" width="140" />}
      title="App Installation Status"
    >
      <Layout>
        <Layout.Section>{children}</Layout.Section>
      </Layout>
      <DashboardFooter />
    </Page>
  );
}

// == Styles ===============================================================
