/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

// == Types ================================================================

export enum Environment {
  production = "production",
  staging = "staging",
  development = "development",
}

// == Constants ============================================================

// == Functions ============================================================

export function missingENVError(envVariable: string): string {
  throw new Error(`API_ENVIRONMENT is ${API_ENVIRONMENT} or ${envVariable} is blank.`);
}

// == Exports ==============================================================

export const PRODUCTION_ENVIRONMENT = Environment.production;
export const STAGING_ENVIRONMENT = Environment.staging;
export const DEVELOPMENT_ENVIRONMENT = Environment.development;

export const IS_SERVER = !!(process.env.NEXT_PUBLIC_IS_SERVER || typeof window === "undefined");
export const IS_CLIENT = !IS_SERVER;
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

export const DEPLOYMENT_ENVIRONMENT = process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT as Environment;
export const API_ENVIRONMENT = (
  DEPLOYMENT_ENVIRONMENT === Environment.development
    ? process.env.NEXT_PUBLIC_DEVELOPMENT_API_ENVIRONMENT || DEPLOYMENT_ENVIRONMENT
    : DEPLOYMENT_ENVIRONMENT
) as Environment;

export const IS_PRODUCTION_API = API_ENVIRONMENT === PRODUCTION_ENVIRONMENT;
export const IS_STAGING_API = API_ENVIRONMENT === STAGING_ENVIRONMENT;
export const IS_DEVELOPMENT_API = API_ENVIRONMENT === DEVELOPMENT_ENVIRONMENT;

export const IS_PRODUCTION = DEPLOYMENT_ENVIRONMENT === PRODUCTION_ENVIRONMENT;
export const IS_STAGING = DEPLOYMENT_ENVIRONMENT === STAGING_ENVIRONMENT;
export const IS_DEVELOPMENT = DEPLOYMENT_ENVIRONMENT === DEVELOPMENT_ENVIRONMENT;

// Main URL
export const BASE_MAIN_URL =
  {
    [PRODUCTION_ENVIRONMENT]: "https://www.flipgive.com",
    [STAGING_ENVIRONMENT]: "https://www.develop.almostflip.com",
    [DEVELOPMENT_ENVIRONMENT]: process.env.NEXT_PUBLIC_DEVELOPMENT_MAIN_URL,
  }[API_ENVIRONMENT] || missingENVError("NEXT_PUBLIC_DEVELOPMENT_MAIN_URL");

// API URL
export const BASE_API_URL =
  {
    [PRODUCTION_ENVIRONMENT]: "https://api.flipgive.com",
    [STAGING_ENVIRONMENT]: "https://api.develop.almostflip.com",
    [DEVELOPMENT_ENVIRONMENT]: process.env.NEXT_PUBLIC_DEVELOPMENT_API_URL,
  }[API_ENVIRONMENT] || missingENVError("NEXT_PUBLIC_DEVELOPMENT_API_URL");

// App Host URL
export const APP_URL =
  {
    [PRODUCTION_ENVIRONMENT]: "https://big-commerce.flipgive.com",
    [STAGING_ENVIRONMENT]: "https://big-commerce.almostflip.com",
    [DEVELOPMENT_ENVIRONMENT]: process.env.NEXT_PUBLIC_DEVELOPMENT_APP_URL,
  }[API_ENVIRONMENT] || missingENVError("NEXT_PUBLIC_DEVELOPMENT_APP_URL");
