import React from "react";
import { SkeletonBodyText, SkeletonBodyTextProps } from "@shopify/polaris";

// == Types ================================================================

// == Constants ============================================================

// == Component ============================================================

export function TextLoadingPlaceholder(props: SkeletonBodyTextProps) {
  return <SkeletonBodyText {...props} />;
}

// == Styles ===============================================================
