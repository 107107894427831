import React from "react";
import { EmptyState, EmptyStateProps } from "@shopify/polaris";

// == Types ================================================================

interface IProps {
  heading: string;
  message: string;
  action: EmptyStateProps["action"];
}

// == Constants ============================================================

EmptyErrorState.defaultProps = {
  heading: "There was an error loading the app.",
  message: "Please contact FlipGive support and we will look into the issue.",
  action: {
    content: "Contact FlipGive Support",
    url: "https://www.flipgive.com/pages/contact",
    external: true,
  },
};

// == Component ============================================================

export function EmptyErrorState({ heading, message, action }: IProps) {
  return (
    <EmptyState
      action={action}
      heading={heading}
      image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
    >
      {message}
    </EmptyState>
  );
}

// == Styles ===============================================================
