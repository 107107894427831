import * as React from "react";
import BugsnagClient, { Client } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import {
  IS_SERVER,
  IS_DEVELOPMENT,
  APP_VERSION,
  PRODUCTION_ENVIRONMENT,
  STAGING_ENVIRONMENT,
  IS_STAGING,
  DEPLOYMENT_ENVIRONMENT,
  DEVELOPMENT_ENVIRONMENT,
} from "@config/environment";

// == Types ================================================================

type TClient = Client & { _client?: Client };

// == Constants ============================================================

const IN_STORYBOOK = process.env.STORYBOOK;

const BugsnagMock = {
  // eslint-disable-next-line no-console
  notify: (error: Error) => console.warn("[Bugnsnag Error]", error),
};

// == Functions ============================================================

// eslint-disable-next-line react/prefer-stateless-function
class NoopErrorBoundary extends React.Component {
  render() {
    const { children } = this.props;
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
}
if (!IN_STORYBOOK && !(BugsnagClient as TClient)._client) {
  BugsnagClient.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? "",
    appType: IS_SERVER ? "server" : "client",
    appVersion: APP_VERSION,
    enabledReleaseStages: [PRODUCTION_ENVIRONMENT, STAGING_ENVIRONMENT, DEVELOPMENT_ENVIRONMENT],
    releaseStage: DEPLOYMENT_ENVIRONMENT,
    plugins: IS_SERVER ? [] : [new BugsnagPluginReact()],
    onError: (report) => {
      // Log a warning to the console if in development or staging.
      // Also skip sending to Bugsnag if in development
      if (IS_DEVELOPMENT || IS_STAGING) {
        console.warn("[Bugsnag Error]", report); // eslint-disable-line no-console
        if (IS_DEVELOPMENT) return false;
      }
      return true;
    },
  });
}

// == Exports ==============================================================

export const Bugsnag = IN_STORYBOOK ? BugsnagMock : BugsnagClient;
export const bugsnagSetUser = BugsnagClient.setUser;

export const BugsnagErrorBoundary = IN_STORYBOOK
  ? NoopErrorBoundary
  : BugsnagClient.getPlugin("react")?.createErrorBoundary(React) ?? NoopErrorBoundary;
